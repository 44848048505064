<template>
    <div class="restpassword">
        <div class="merchatlogin-wrap">
            <div class="merchatlogin-wrap-top">
                <img src="../../../assets/sj_bj.png" alt />
            </div>
            <el-form :model="postData" status-icon  ref="ruleForm"
                class="merchatlogin-wrap-login demo-ruleForm">
                <el-form-item>
                    <el-input placeholder="请输入手机号" type="text" v-model="postData.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="new_pass">
                    <el-input placeholder="请输入新密码" type="text" v-model="postData.new_pass" autocomplete="off">
                    </el-input>
                </el-form-item>
                <!-- <el-form-item prop="re_pass">
                    <el-input
                        placeholder="请再次输入新密码"
                        type="password"
                        v-model="postData.re_pass"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>-->
                <el-form-item prop="captcha" class="yanzhen">
                    <el-input placeholder="短信验证码" v-model="postData.captcha"></el-input>
                    <el-button type="primary" :class="isphone?'':'falseTap'" :disabled="isDisabled" @click="sendCode">
                        {{buttonText}}
                    </el-button>
                </el-form-item>
                <p class="submit" @click="submitData" :class="isphone?'':'falseTap'">提交</p>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {
        sendsmsResetPass,
        resetPass
    } from "../../../http/api.js";
    export default {
        data() {
            return {
                postData: {
                    new_pass: "",
                    captcha: "",
                    re_pass: "",
                    phone: ""
                },
                isDisabled: false, // 是否禁止点击发送验证码按钮
                flag: true,
                isphone: true,
                buttonText: "发送验证码"
            };
        },

        beforeCreate() {
            if (!this.$LStorage.getItem("shopUserInfo")) {
                this.$router.replace({
                    path: "/merchatlogin"
                });
            }
        },
        mounted() {
            this.shopUserInfo = this.$LStorage.getItem("shopUserInfo");
            this.postData.phone = this.shopUserInfo.user_phone
        },
        methods: {
            // <!--发送验证码-->
            sendCode() {
                let reg = /^1[3456789]\d{9}$/;
                if (!reg.test(this.postData.phone)) {
                    this.$toast("手机号格式有误");
                    return;
                } else {
                    let time = 60;
                    this.buttonText = "已发送";
                    this.isDisabled = true;
                    if (this.flag) {
                        this.sendMsg();
                        this.flag = false;
                        let timer = setInterval(() => {
                            time--;
                            this.buttonText = time + " 秒";
                            if (time === 0) {
                                clearInterval(timer);
                                this.buttonText = "重新获取";
                                this.isDisabled = false;
                                this.flag = true;
                            }
                        }, 1000);
                    }
                }
            },
            async sendMsg() {
                const res = await sendsmsResetPass({
                    data: {
                        shop_user_id: this.shopUserInfo.shop_user_id,
                        user_phone: this.postData.phone
                    }
                });
            },
            submitData() {
                let reg = /^1[3456789]\d{9}$/;
                if (!reg.test(this.postData.phone)) {
                    this.$toast("手机号格式有误");
                    return;
                } else if (this.postData.new_pass == "") {
                    this.$toast("请输入新密码");
                    return;
                } else if (this.postData.captcha == "") {
                    this.$toast("请输入验证码");
                    return;
                } else {
                    this.resetPass()
                }
            },
            async resetPass() {
                const res = await resetPass({
                    data: {
                        shop_user_id: this.shopUserInfo.shop_user_id,
                        user_phone: this.postData.phone,
                        captcha: this.postData.captcha,
                        new_pass: this.postData.new_pass
                    }
                });
                if (res.code == "200") {
                    this.$toast.success("密码重置成功");
                    this.$router.go(-1);
                } else {
                    this.$toast(res.msgs);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .falseTap {
        background: #ccc !important;
        border: none !important;
    }

    .restpassword {
        width: 100%;
        height: 13.5rem;
        background: #f6f6f6;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .merchatlogin-wrap {
            img {
                width: 100%;
                height: 4.54rem;

            }
        }

        .merchatlogin-wrap-login {
            width: 5.6rem;
            margin: 0 auto;
            background: #fff;
            border-radius: 0.07rem;
            padding: 0.39rem 0.45rem 0.45rem 0.52rem;
            box-sizing: border-box;
            margin-top: 0.45rem;

            input {
                width: 4.55rem;
                height: 0.73rem;
                background: #f5f5f5;
                font-size: 0.27rem;
            }

            input:nth-child(1) {
                margin-bottom: 0rem;
            }

            input:nth-child(2) {
                margin-bottom: 0.27rem;
            }

            .yanzhen {
                margin-top: 0.26rem;

                /deep/ .el-form-item__content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .el-input {
                        width: 2.7rem;
                        background: #f5f5f5;
                        margin-right: 0.15rem;
                    }

                    button {
                        width: 1.69rem;
                        height: 40px;
                        padding: 0;
                        background-color: #e14e2a;
                        border-color: #e14e2a;
                        font-size: 0.24rem;
                    }
                }
            }

            .submit {
                width: 4.55rem;
                height: 0.73rem;
                border-radius: 0.04rem;
                font-size: 0.26rem;
                color: #fff;
                background: #e14e2a;
                margin-top: 0.99rem;
                text-align: center;
                line-height: 0.73rem;
            }

            .password {
                font-size: 0.23rem;
                display: flex;
                justify-content: flex-end;
                color: #e14e2a;
                margin-top: 0.36rem;
            }
        }
    }
</style>
